const data = {
  sectionConfig: {
    padding: {
      desktop: '40px 0',
      tablet: '40px 0',
      mobile: '40px 0'
    }
  },
  company: '',
  provider: 'STRATO',
  customCookieScriptTag: {
    id: 'CookieDeclaration',
    src:
      'https://consent.cookiebot.com/4d983a4d-4162-4b89-9a20-5ec34d4e8a86/cd.js',
    type: 'text/javascript',
    async: true
  }
}

export default data
