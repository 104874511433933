import { ContainerBox, Datenschutz } from '@peakconcepts/landingpage-kit'
import seoHeaderData from '../../content/data/seoHeader'

import leadData from './data/lead'
import imprintData from './data/imprint'
import policyData from './data/policy'

export const headerConfig = {
  withContainer: true,
  containerConfig: {
    padding: {
      fullHd: '20px 0',
      widescreen: '20px 0',
      desktop: '20px 0',
      tablet: '20px 0',
      mobile: '10px 0'
    },
    defaultCss: {
      backgroundColor: 'unset'
    }
  },
  brandConfig: {
    className: 'ml-0',
    defaultCss: { justifyContent: 'center' },
    imageContainer: {
      className: 'p-0'
    },
    image: {
      src: '/images/logo.svg',
      width: '130px',
      defaultCss: {
        maxHeight: 'unset !important'
      }
    }
  },
  burgerConfig: {
    hide: true
  },
  menuEndConfig: undefined
}

const data = [
  {
    path: '/impressum',
    headerEnabled: true,
    headerConfig,
    seoHeaderConfig: seoHeaderData.imprint,
    component: ContainerBox,
    data: {
      ...imprintData
    }
  },
  {
    path: '/datenschutz',
    headerEnabled: true,
    headerConfig,
    seoHeaderConfig: seoHeaderData.policy,
    component: Datenschutz,
    data: {
      ...policyData
    }
  },
  {
    id: 'Danke',
    path: '/danke',
    headerEnabled: true,
    headerConfig,
    seoHeaderConfig: seoHeaderData.thankYou,
    component: ContainerBox,
    data: {
      ...leadData
    }
  }
]

export default data
