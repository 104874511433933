import {
  AvailableContainerBoxVariants,
  ContainerBoxInterface
} from '@peakconcepts/landingpage-kit'

import ctaButton from './ctaButtonBewertung'

const itemTitleConfig = {
  size: 3,
  defaultCss: {
    textAlign: 'left'
  },
  margin: {
    desktop: '0 0 10px 0',
    tablet: '0 0 10px 0',
    mobile: '0 0 10px 0'
  }
}

const itemDescriptionConfig = {
  defaultCss: {
    textAlign: 'left'
  },
  margin: {
    desktop: '0 80px 20px 0',
    tablet: '0 80px 20px 0',
    mobile: '0 0 20px 0'
  }
}

const data: ContainerBoxInterface = {
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '60px 40px',
        mobile: '40px 20px'
      },
      containers: [
        {
          classNames: 'columns is-multiline',
          variant: AvailableContainerBoxVariants.ReverseWidescreen,
          containers: [
            {
              classNames:
                'column is-12-mobile is-12-tablet is-12-desktop is-6-widescreen is-flex is-flex-direction-column is-justify-content-center',
              containers: [
                {
                  type: 'text',
                  config: {
                    text: 'Keine Sorge,<br/> es gibt eine gute Nachricht',
                    ...itemTitleConfig
                  }
                },
                {
                  type: 'hr',
                  config: {
                    height: 4,
                    width: '150px',
                    defaultCss: {
                      color: 'primary'
                    },
                    margin: {
                      desktop: '20px 0 30px',
                      tablet: '20px 0 30px',
                      mobile: '20px 0 30px'
                    }
                  }
                },
                {
                  type: 'text',
                  config: {
                    text:
                      'Es war noch nie so einfach <b>wie jetzt</b>, Häuser und Wohnungen zu Spitzenpreisen zu verkaufen - <u>vorausgesetzt die Taktik stimmt</u>.',
                    ...itemDescriptionConfig
                  }
                },
                {
                  type: 'text',
                  config: {
                    text:
                      'Denn mit der richtigen Vorgehensweise kann man zwischen mehreren Käufern auswählen und sich die Person aussuchen, die den <u>besten</u> Preis bezahlt.',
                    ...itemDescriptionConfig
                  }
                },
                {
                  type: 'text',
                  config: {
                    text: '<b>Und genau dabei helfen wir Ihnen.</b>',
                    ...itemDescriptionConfig
                  }
                },
                {
                  type: 'text',
                  config: {
                    text:
                      'Wir finden den idealen Käufer, der genau den richtigen Wert oder sogar mehr zahlt.',
                    ...itemDescriptionConfig,
                    margin: {
                      desktop: '0 80px 0 0',
                      tablet: '0 80px 0 0',
                      mobile: '0'
                    }
                  }
                },
                {
                  type: 'cta',
                  config: {
                    dark: false,
                    ...ctaButton,
                    margin: {
                      desktop: '50px 0 0',
                      tablet: '50px 0 0',
                      mobile: '30px 0 0'
                    }
                  }
                }
              ]
            },
            {
              classNames:
                'column is-12-mobile is-12-tablet is-12-desktop is-6-widescreen',
              containers: [
                {
                  type: 'image',
                  config: {
                    defaultCss: {
                      boxShadow: '10px 10px 30px 0px rgba(0,0,0,0.25)'
                    },
                    src: '/images/2.jpg'
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
