import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import colors from '../../content/default/colors'
import FAQs from '../../components/FAQs'

const data: ContainerBoxInterface = {
  defaultCss: {
    backgroundColor: colors.secondary
  },
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '60px 40px',
        mobile: '40px 20px 60px'
      },
      containers: [
        {
          type: 'text',
          config: {
            text: 'Häufig gestellte Fragen',
            size: 2
          }
        },
        {
          type: 'hr',
          config: {
            height: 4,
            width: '150px',
            defaultCss: {
              color: 'primary'
            },
            margin: {
              desktop: '30px auto 50px',
              tablet: '30px auto 50px',
              mobile: '30px auto 40px'
            }
          }
        },
        {
          type: 'component',
          component: FAQs,
          config: {
            items: [
              {
                headerConfig: {
                  textConfig: {
                    text: 'Warum sollte ich einen Makler beauftragen?'
                  }
                },
                contentConfig: {
                  textConfig: {
                    text:
                      'Für den Kauf Ihrer Immobilie sollten Sie unbedingt einen Makler beauftragen. Zumindest wenn Sie keine teuren Fehler machen möchten. Sie profitieren von einem höheren Verkaufspreis und einem schnellen, sicheren Verkauf.'
                  }
                }
              },
              {
                headerConfig: {
                  textConfig: {
                    text: 'Wie lange dauert es, eine Immobilie zu verkaufen?'
                  }
                },
                contentConfig: {
                  textConfig: {
                    text:
                      'Durch die aktuell sehr hohe Nachfrage und unser großes Netzwerk ist es uns nicht nur möglich einen hohen Verkaufspreis zu erzielen, sondern auch schnell den passenden Käufer zu finden. In der Regel verkaufen wir in wenigen Wochen Ihr Objekt. Wenn Sie herausfinden möchten wie hoch der aktuelle Wert Ihrer Immobilie ist und wie lange es dauern würde, einen passenden Käufer zu finden, dann starten Sie jetzt unsere kostenlose Immobilienbewertung.'
                  }
                }
              },
              {
                headerConfig: {
                  textConfig: {
                    text:
                      'Wie schaffen wir es, so hohe Verkaufspreise zu erzielen?'
                  }
                },
                contentConfig: {
                  textConfig: {
                    text:
                      'Durch unsere langjährige Erfahrung haben wir Zugriff auf ein riesiges Netzwerk aus liquiden Käufern, die bereit sind, einen fairen Preis für Ihre Immobilie zu bezahlen. Außerdem gehen wir nach einer erfolgserprobten Verkaufsstrategie vor, hinter der ein ausgeklügeltes Marketingkonzept steckt.'
                  }
                }
              },
              {
                headerConfig: {
                  textConfig: {
                    text:
                      'Was passiert nachdem ich mir einen Termin gebucht habe?'
                  }
                },
                contentConfig: {
                  textConfig: {
                    text:
                      'Nachdem Sie einen Termin gebucht haben, melden wir uns innerhalb von 1-2 Werktagen auf Ihre Anfrage. In einem kostenlosen Erstgespräch beantworten wir Ihnen alle Fragen zu einer Zusammenarbeit. Sollten alle Voraussetzungen erfüllt sein, vereinbaren wir einen Vorort-Termin, in dem wir Ihre Immobilie noch einmal begutachten. Im Anschluss erhalten Sie Ihre kostenlose Wertermittlung.'
                  }
                }
              },
              {
                headerConfig: {
                  textConfig: {
                    text: 'Warum ist unsere Wertermittlung kostenlos?'
                  }
                },
                contentConfig: {
                  textConfig: {
                    text:
                      'Wir möchten Sie mit unserer Kompetenz überzeugen. Daher ist unsere Immobilienbewertung für Sie als Eigentümer vollständig kostenlos. Sollten Sie von uns überzeugt sein, erst dann schließen wir einen Maklervertrag. Die Vermittlungsgebühr fällt erst zu dem Zeitpunkt an, an dem wir Ihre Immobilie verkauft haben.'
                  }
                }
              },
              {
                headerConfig: {
                  textConfig: {
                    text: 'Wie aufwendig ist es, meine Immobilie zu verkaufen?'
                  }
                },
                contentConfig: {
                  textConfig: {
                    text:
                      'Eine Immobilie ohne Erfahrung zu verkaufen, ist meist aufwendiger als sich das viele vorstellen. Da wir Ihnen beim gesamten Verkaufsprozess Ihrer Immobilie zur Seite stehen, ist es für sie allerdings stressfrei und einfach, da wir die Arbeit übernehmen. Wir ermitteln den Wert Ihrer Immobilie, kümmern uns um die ganzen Unterlagen, die Sie für den Verkaufsprozess benötigen und führen mit Ihnen den Notartermin durch.'
                  }
                }
              },
              {
                headerConfig: {
                  textConfig: {
                    text: 'Sind Immobilienmakler nicht teuer?'
                  }
                },
                contentConfig: {
                  textConfig: {
                    text:
                      'Die Maklerprovision richtet sich nach gesetzlichen Vorgaben und beträgt in der Regel ca. 3-4% für Sie. Für viele Verkäufer ist das ein Grund, den Verkauf des Wohneigentums selbst in die Hand zu nehmen. Ein großer Denkfehler, denn wenn man genau rechnet, ist ein guter Makler nicht teuer. Der erheblich geringere Verwaltungs- und Vermarktungsaufwand, die Erzielung eines optimalen Verkaufspreises und nicht zuletzt die Gewissheit, den Hausverkauf rechtlich korrekt abzuwickeln, sind nur wenige Gründe, warum Sie einen Immobilienmakler beauftragen sollten. Denn richtig teuer wird es erst, wenn man seine Immobilie unter Wert verkauft oder monatelang warten muss, bis man einen passenden Käufer findet.'
                  }
                }
              }
            ]
          }
        }
      ]
    }
  ]
}

export default data
