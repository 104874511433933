import colors from '../colors'

const data = {
  /** Theme */
  theme: {
    primary: colors.primary,
    fonts: [
      {
        types: ['HEADLINE', 'DEFAULT'],
        fontFamily: 'Open Sans',
        fontWeight: 'wght@400;700;800'
      }
    ]
  },
  /** TagManager */
  gtmID: 'GTM-K5PD6QF',
  /** Calendly */
  calendly: {
    pageSettings: {
      backgroundColor: 'ffffff',
      hideEventTypeDetails: false,
      hideLandingPageDetails: false,
      primaryColor: colors.primary,
      textColor: '000000'
    },
    url: '',
    isPage: true
  },
  provenExpert: {
    href:
      'https://www.provenexpert.com/wuestenrot-immobilien-sascha-maurer/?utm_source=Widget&amp;utm_medium=Widget&amp;utm_campaign=Widget',
    title:
      'Kundenbewertungen &amp; Erfahrungen zu Wüstenrot Immobilien Sascha Maurer. Mehr Infos anzeigen.',
    src:
      'https://images.provenexpert.com/88/5f/b5e03a154cd1d1cc5a9aaa69ec67/widget_portrait_180_de_0.png',
    alt: 'Erfahrungen &amp; Bewertungen zu Wüstenrot Immobilien Sascha Maurer'
  }
}

export default data
