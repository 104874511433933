const general = {
  title: 'Immobilienverkauf » Wüstenrot Immobilien GmbH',
  description:
    'Erfahren Sie jetzt, wie viel Ihre Immobilie wirklich wert ist und erhalten Sie in kurzer Zeit einen Verkaufspreis von bis zu 30 % über Wert.',
  author: '',
  publisher: '',
  revisitAfter: '7 days',
  type: 'website',
  image: 'share/share.png',
  site: '',
  twitterCard: 'summary_large_image',
  creator: ''
}

const data = {
  main: {
    ...general
  },
  ads: {
    verkauf: {
      haus: {
        ...general,
        title: 'Haus verkaufen'
      },
      wohnung: {
        ...general,
        title: 'Wohnung verkaufen'
      },
      einfamilienhaus: {
        ...general,
        title: 'Einfamilienhaus verkaufen'
      },
      doppelhaus: {
        ...general,
        title: 'Doppelhaus verkaufen'
      },
      doppelhaushaelfte: {
        ...general,
        title: 'Doppelhaushälfte verkaufen'
      },
      mehrfamilienhaus: {
        ...general,
        title: 'Mehrfamilienhaus verkaufen'
      },
      villa: {
        ...general,
        title: 'Villa verkaufen'
      },
      immobilie: {
        ...general,
        title: 'Immobilie verkaufen'
      },
      eigentumswohnung: {
        ...general,
        title: 'Eigentumswohnung verkaufen'
      },
      etw: {
        ...general,
        title: 'ETW verkaufen'
      },
      eigenheim: {
        ...general,
        title: 'Eigenheim verkaufen'
      },
      reihenhaus: {
        ...general,
        title: 'Reihenhaus verkaufen'
      },
      grundstueck: {
        ...general,
        title: 'Grundstück verkaufen'
      }
    }
  },
  imprint: {
    ...general,
    title: 'Impressum » Wüstenrot Immobilien GmbH'
  },
  policy: {
    ...general,
    title: 'Datenschutz » Wüstenrot Immobilien GmbH'
  },
  thankYou: {
    ...general,
    title: 'Danke » Wüstenrot Immobilien GmbH'
  }
}

export default data
