import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import colors from '../../content/default/colors'

const itemCardConfig = {
  defaultCss: {
    textAlign: 'center',
    boxShadow: '10px 10px 30px 0px rgba(0, 0, 0, 0.25)',
    position: 'relative',
    padding: '50px 30px 30px',
    backgroundColor: '#fff'
  },
  margin: {
    desktop: '40px 0 0',
    tablet: '40px 0 0',
    mobile: '40px 0 0'
  }
}

const itemTitleConfig = {
  size: 6,
  defaultCss: {
    textAlign: 'center'
  }
}

const itemDescriptionConfig = {
  defaultCss: {
    textAlign: 'center',
    fontStyle: 'italic'
  }
}

const itemNameConfig = {
  defaultCss: {
    textAlign: 'center'
  }
}

const itemImageConfig = {
  height: {
    desktop: '100px'
  },
  defaultCss: {
    borderRadius: '50%'
  },
  margin: {
    desktop: '-90px 0 30px',
    tablet: '-90px 0 30px',
    mobile: '-90px 0 20px'
  }
}

const data: ContainerBoxInterface = {
  nativeProps: {
    id: 'vorteile'
  },
  defaultCss: {
    backgroundColor: colors.secondary
  },
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '60px 40px',
        mobile: '40px 20px'
      },
      containers: [
        {
          type: 'text',
          config: {
            size: 2,
            text: 'Das sagen unsere Kunden'
          }
        },
        {
          type: 'hr',
          config: {
            height: 4,
            width: '150px',
            defaultCss: {
              color: 'primary'
            },
            margin: {
              desktop: '30px auto 50px',
              tablet: '30px auto',
              mobile: '30px auto'
            }
          }
        },
        {
          classNames: 'columns is-multiline',
          containers: [
            {
              classNames:
                'column is-12-mobile is-12-tablet is-12-desktop is-4-widescreen is-flex',
              containers: [
                {
                  ...itemCardConfig,
                  containers: [
                    {
                      type: 'image',
                      config: {
                        src: '/images/reviews/xxx.jpg',
                        ...itemImageConfig
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        text: 'Klare Empfehlung',
                        ...itemTitleConfig
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        text:
                          '”Wir waren sehr zufrieden mit dem kompletten Prozess um den Immobilienkauf. Sowohl Herr Maurer, wie auch Frau Szulwach waren immer freundlich, gaben schnell Rückmeldungen und kümmerten sich um jedes Anliegen. Von unserer Seite eine klare Empfehlung!”',
                        ...itemDescriptionConfig
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        text: '⭐⭐⭐⭐⭐ <br/>Tobias D.',
                        ...itemNameConfig
                      }
                    }
                  ]
                }
              ]
            },
            {
              classNames:
                'column is-12-mobile is-12-tablet is-12-desktop is-4-widescreen is-flex',
              containers: [
                {
                  ...itemCardConfig,
                  containers: [
                    {
                      type: 'image',
                      config: {
                        src: '/images/reviews/xxx.jpg',
                        ...itemImageConfig
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        text: 'Beste was uns passieren konnte',
                        ...itemTitleConfig
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        text:
                          '”Herausragend kompetente und persönliche Betreuung während des gesamten Immobilienverkaufs durch Herrn Maurer und Frau Szulwach! Absolut empfehlenswert und unseres Erachtens das Beste was uns passieren konnte! Herzlichen Dank!”',
                        ...itemDescriptionConfig
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        text: '⭐⭐⭐⭐⭐ <br/>Michael D.',
                        ...itemNameConfig
                      }
                    }
                  ]
                }
              ]
            },
            {
              classNames:
                'column is-12-mobile is-12-tablet is-12-desktop is-4-widescreen is-flex',
              containers: [
                {
                  ...itemCardConfig,
                  containers: [
                    {
                      type: 'image',
                      config: {
                        src: '/images/reviews/xxx.jpg',
                        ...itemImageConfig
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        text: '1a Makler',
                        ...itemTitleConfig
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        text:
                          '”Herr Maurer ist einer meiner Kollegen und betreut als Makler meine Bestandskunden im Bereich Immobilienverkauf und Bewertung. Die an Ihn vermittelten Kunden waren bisher allesamt am Ende hochzufrieden. Ich arbeite daher gerne mit Herrn Maurer zusammen. 1a Makler”',
                        ...itemDescriptionConfig
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        text: '⭐⭐⭐⭐⭐ <br/>Ralf S.',
                        ...itemNameConfig
                      }
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
